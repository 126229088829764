import {
  Box,
  Button,
  Card,
  Cell,
  CircularProgressBar,
  FormField,
  Layout,
  Loader,
  Notification,
  Page,
  SectionHelper,
  ToggleSwitch,
  WixDesignSystemProvider,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import React, { useEffect } from "react";
import { ReactTagManager } from "react-gtm-ts";

import "./App.css";
import { useTranslation } from "react-i18next";
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  const { t } = useTranslation()
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [trialRemainingDate, setTrialRemainingDate] = React.useState(0);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [options, setOptions] = React.useState({
    disableSelectAll: true,
    disableCopy: true,
    disableCut: true,
    disablePaste: true,
    disableSave: true,
    disableViewSource: true,
    disablePrintPage: true,
    disableDeveloperTool: true,
    disableReaderMode: true,
    disableRightClick: true,
    disableTextSelection: true,
    disableImageDragging: true,
  });

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  const isFree = instanceData?.instance?.isFree !== false;

  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
    getTrial();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setSettings();
    } else {
      setIsLoading(false);
    }
  }, [isEnabled, options]);

  const BASE_URL = `https://certifiedcode.wixsite.com/guard/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
        setIsUpgradeBannerOpen(data.instance.isFree !== false);
      })
      .catch(() => {});
  }

  function getSettings() {
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsEnabled(data.isEnabled);
        if (data?.options) setOptions(data?.options);
      })
      .catch(() => {});
  }

  function setSettings() {
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        isEnabled: isEnabled,
        options: options,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsEnabled(data.isEnabled);
      })
      .catch(() => {});
  }

  function getTrial() {
    fetch(`${BASE_URL}/premium`, {
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTrialRemainingDate(data?.trial);
      });
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=c5d70082-a3ab-40bc-8146-0e6271f1ca3b&redirectUrl=https://certifiedcode.wixsite.com/guard/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t('loading')} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const upgradeUrl = `https://www.wix.com/apps/upgrade/${"c5d70082-a3ab-40bc-8146-0e6271f1ca3b"}?appInstanceId=${
    (instanceData as any)?.instance?.instanceId
  }`;
  const time = trialRemainingDate;
  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>
          {t('upgradeMessage')}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeUrl, "_blank");
          }}
        >
          {t('upgrade-button')}
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Page
        className={`${isUpgradeBannerOpen ? "App-header-notification" : ""}`}
      >
        <Page.Header
          title={t('guard-title')}
          subtitle={t('guard-content')}
          actionsBar={
            <Box direction="horizontal" gap="SP2">
              <Button
                skin="premium"
                as="a"
                href={upgradeUrl}
                target="_blank"
                prefixIcon={<Icons.PremiumFilled />}
              >
                {t('guard-upgrade-button')}
              </Button>
              {isUpgraded && (
                <Button
                  skin="inverted"
                  as="a"
                  href="https://bridget.reviews.certifiedcode.us/c5d70082-a3ab-40bc-8146-0e6271f1ca3b"
                  target="_blank"
                >
                  {t('rate-button')}
                </Button>
              )}
            </Box>
          }
        />
        <Page.Content>
          <Layout>
            {isEnabled && (
              <Cell>
                <SectionHelper
                  title={t('helper-title')}
                  appearance="warning"
                >
                 {t('helper-text')}
                </SectionHelper>
              </Cell>
            )}
            <Cell span={9}>
              <Box direction="vertical" gap="SP1">
                <Card>
                  <Card.Header
                    title={t('enable-guard-title')}
                    subtitle={t('enable-guard-subtitle')}
                    suffix={
                      <ToggleSwitch
                        checked={isEnabled}
                        onChange={() => setIsEnabled(!isEnabled)}
                      />
                    }
                  />
                  <Card.Content>
                    {isEnabled && (
                      <Layout>
                        <Cell span={3}>
                          <FormField label={t('enable-disable-label')}>
                            <ToggleSwitch
                              checked={options.disableSelectAll}
                              onChange={() =>
                                setOptions({
                                  ...options,
                                  disableSelectAll: !options.disableSelectAll,
                                })
                              }
                            />
                          </FormField>
                          <FormField label={t('enable-disable-copy')}>
                            <ToggleSwitch
                              checked={options.disableCopy}
                              onChange={() =>
                                setOptions({
                                  ...options,
                                  disableCopy: !options.disableCopy,
                                })
                              }
                            />
                          </FormField>
                          <FormField label={t('enable-disable-cut')}>
                            <ToggleSwitch
                              checked={options.disableCut}
                              onChange={() =>
                                setOptions({
                                  ...options,
                                  disableCut: !options.disableCut,
                                })
                              }
                            />
                          </FormField>
                          <FormField label={t('enable-disable-paste')}>
                            <ToggleSwitch
                              checked={options.disablePaste}
                              onChange={() =>
                                setOptions({
                                  ...options,
                                  disablePaste: !options.disablePaste,
                                })
                              }
                            />
                          </FormField>
                        </Cell>
                        <Cell span={3}>
                          <FormField label={t('enable-disable-save')}>
                            <ToggleSwitch
                              checked={options.disableSave}
                              onChange={() =>
                                setOptions({
                                  ...options,
                                  disableSave: !options.disableSave,
                                })
                              }
                            />
                          </FormField>
                          <FormField label={t('enable-disable-source')}>
                            <ToggleSwitch
                              checked={options.disableViewSource}
                              onChange={() =>
                                setOptions({
                                  ...options,
                                  disableViewSource: !options.disableViewSource,
                                })
                              }
                            />
                          </FormField>
                          <FormField label={t('enable-disable-print')}>
                            <ToggleSwitch
                              checked={options.disablePrintPage}
                              onChange={() =>
                                setOptions({
                                  ...options,
                                  disablePrintPage: !options.disablePrintPage,
                                })
                              }
                            />
                          </FormField>
                          <FormField label={t('enable-disable-developer')}>
                            <ToggleSwitch
                              checked={options.disableDeveloperTool}
                              onChange={() =>
                                setOptions({
                                  ...options,
                                  disableDeveloperTool:
                                    !options.disableDeveloperTool,
                                })
                              }
                            />
                          </FormField>
                          <FormField label={t('enable-disable-reader')}>
                            <ToggleSwitch
                              checked={options.disableReaderMode}
                              onChange={() =>
                                setOptions({
                                  ...options,
                                  disableReaderMode: !options.disableReaderMode,
                                })
                              }
                            />
                          </FormField>
                          <FormField label={t('enable-disable-right')}>
                            <ToggleSwitch
                              checked={options.disableRightClick}
                              onChange={() =>
                                setOptions({
                                  ...options,
                                  disableRightClick: !options.disableRightClick,
                                })
                              }
                            />
                          </FormField>
                          <FormField label={t('enable-disable-text')}>
                            <ToggleSwitch
                              checked={options.disableTextSelection}
                              onChange={() =>
                                setOptions({
                                  ...options,
                                  disableTextSelection:
                                    !options.disableTextSelection,
                                })
                              }
                            />
                          </FormField>
                          <FormField label={t('enable-disable-image')}>
                            <ToggleSwitch
                              checked={options.disableImageDragging}
                              onChange={() =>
                                setOptions({
                                  ...options,
                                  disableImageDragging:
                                    !options.disableImageDragging,
                                })
                              }
                            />
                          </FormField>
                        </Cell>
                      </Layout>
                    )}
                  </Card.Content>
                </Card>
              </Box>
            </Cell>
            <Cell span={3}>
              <Card>
                <Card.Content>
                  <CircularProgressBar
                    label={
                      isUpgraded
                        ? t('premium-msg')
                        :t('trial-msg', { trialRemainingDate })
                    }
                    skin={isUpgraded ? "standard" : "premium"}
                    showProgressIndication
                    value={isUpgraded ? 100 : 1 - trialRemainingDate / 30}
                  />
                </Card.Content>
              </Card>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
